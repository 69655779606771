import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші Послуги | Spectravo – створення незабутніх вражень
			</title>
			<meta name={"description"} content={"Ваш головний вибір для виключного планування та організації заходів"} />
			<meta property={"og:title"} content={"Наші Послуги | Spectravo – створення незабутніх вражень"} />
			<meta property={"og:description"} content={"Ваш головний вибір для виключного планування та організації заходів"} />
			<meta property={"og:image"} content={"https://spectravo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://spectravo.com/img/3463464.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://spectravo.com/img/3463464.png"} />
			<meta name={"msapplication-TileImage"} content={"https://spectravo.com/img/3463464.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Відкрийте для себе наші виняткові послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			У Spectravo ми пишаємося тим, що пропонуємо широкий спектр послуг, що задовольняють всі аспекти планування та організації заходів. Наша мета – забезпечити безперешкодний та незабутній досвід для наших клієнтів та їх гостей. Від розробки концепції до її реалізації наша команда гарантує, що кожна деталь буде ретельно спланована і бездоганно виконана.
			</Text>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="url(https://spectravo.com/img/5.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Розробка концепції та дизайну заходу: Ми працюємо з вами, щоб розробити унікальну концепцію та дизайн вашого заходу. Наша творча команда пропонує інноваційні ідеї, забезпечуючи незабутню і візуально приголомшливу подію.
  <br />
  <br />
 
 Розробка теми: Створення цілісної теми, яка відображає ваше бачення та покращує загальне враження.
  <br />
  <br />
 
 Декор та стилізація: Підбір та розташування елементів декору для створення красивого та цілісного образу.
  <br />
  <br />
 Графічний дизайн: Індивідуальні запрошення, програми та вивіски, що відповідають тематиці вашого заходу.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="url(https://spectravo.com/img/6.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Вибір та керування місцем проведення заходу: Пошук ідеального місця для проведення вашого заходу має вирішальне значення, і наша команда має великий досвід у виборі та управлінні місцем проведення заходу.
  <br />
  <br />
 Пошук місця проведення: Пошук місць, які відповідають вашим вимогам та бюджету.
  <br />
  <br />
 Переговори щодо контракту: Ведення всіх переговорів для отримання найкращих умов та тарифів.
  <br />
  <br />
 Координація на місці: Забезпечення безперебійної роботи на день заходу.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="url(https://spectravo.com/img/7.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Кейтеринг та складання меню: Порадуйте своїх гостей вишуканими стравами та напоями, підібраними відповідно до тематики та уподобань вашого заходу.
  <br />
  <br />
 Розробка меню: Складання індивідуальних меню з урахуванням різноманітних смаків та дієтичних вимог.
  <br />
  <br />
 Дегустаційні сесії: Організація дегустацій, щоб ви залишилися задоволені вибором.
  <br />
  <br />
 Презентація страв: Координація роботи з постачальниками провізії для оформлення страв відповідала тематиці заходу.
					</Text>
				</Box>
			</Box>
		</Section>

		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			/>
			<Box
				width="85%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
			<Text
					margin="0px 0px 20px 0px"
					font="normal 500 28px/1.2 --fontFamily-sans"
					display="flex"
					align-items="flex-end"
					padding="30px 15px 15px 15px"
					color="#F7FBFF"
				>
					Давайте творити диво разом
				</Text>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
				У Spectravo ми віримо, що кожна подія має бути шедевром. Дозвольте нам допомогти вам створити незвичайну подію, яка залишить незабутнє враження у ваших гостей.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="90%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'яжіться з нами
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Ми запрошуємо вас зв'язатися з нами, щоб дізнатися більше про те, як ми можемо зробити ваш захід незвичайним. Наша команда готова відповісти на будь-які питання та надати додаткову інформацію про наші послуги. Відвідайте наш офіс, зателефонуйте нам або надішліть листа електронною поштою, щоб почати планувати свій ідеальний захід.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});